var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home-wrap" }, [
    _c("div", { staticClass: "content-outer" }, [
      _c("div", { staticClass: "head-wrap" }, [
        _c(
          "div",
          { staticClass: "head-inner" },
          [
            _c("userLogoInfo", {
              attrs: {
                "user-id": _vm.userInfo.userId,
                "is-show-circle": true,
                "user-name": _vm.userInfo.name || "",
                "user-logo": _vm.userInfo.userLogo,
                logoSize: 91,
              },
            }),
            _c("div", { staticClass: "head-tit-wrap" }, [
              _c("div", { staticClass: "head-tit-inner" }, [
                _c("div", { staticClass: "head-tit" }, [
                  _vm._v(" " + _vm._s(_vm.userInfo.name) + " "),
                ]),
                !_vm.$route.query.userId
                  ? _c("div", { staticClass: "head-info" }, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.msgCount > 0 || _vm.count > 0,
                              expression: "msgCount>0 || count>0",
                            },
                          ],
                        },
                        [_vm._v("您有")]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.msgCount > 0,
                              expression: "msgCount>0",
                            },
                          ],
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "head-number",
                              on: { click: _vm.toSeeNews },
                            },
                            [_vm._v(" " + _vm._s(_vm.msgCount) + " ")]
                          ),
                          _vm._v(" 条新信息"),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.msgCount > 0 && _vm.count > 0,
                              expression: "msgCount>0 && count>0",
                            },
                          ],
                        },
                        [_vm._v(",")]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.count > 0,
                              expression: "count>0",
                            },
                          ],
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "head-number",
                              on: { click: _vm.toSeeNews },
                            },
                            [_vm._v(" " + _vm._s(_vm.count) + " ")]
                          ),
                          _vm._v(" 条待回执信息"),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
              !_vm.$route.query.userId
                ? _c(
                    "div",
                    { staticClass: "head-release-wrap" },
                    [
                      _c(
                        "en-button",
                        {
                          attrs: { icon: "iconbianji1", type: "text" },
                          on: { click: _vm.isShowReleasePanel },
                        },
                        [_vm._v(" 发布说说 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "scroll",
              rawName: "v-scroll",
              value: _vm.getCommentList,
              expression: "getCommentList",
            },
          ],
          key: _vm.$route.query.userId,
          ref: "contentWrap",
          staticClass: "content-wrap",
        },
        [
          _c("transition", { attrs: { name: "el-fade-in-linear" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: this.isShowRelease,
                    expression: "this.isShowRelease",
                  },
                ],
                staticClass: "content-inner",
              },
              [
                _c("div", { staticClass: "form-wrap" }, [
                  _c("div", { staticClass: "form-text" }, [
                    _c(
                      "div",
                      { staticClass: "user-wrap" },
                      [
                        _vm.showType == 1
                          ? [
                              _c("span", { staticClass: "user-mod" }, [
                                _vm._v("#公开"),
                              ]),
                            ]
                          : _vm._e(),
                        _vm.showType == 2
                          ? [
                              _c("span", { staticClass: "user-mod" }, [
                                _vm._v("@本部门"),
                              ]),
                            ]
                          : _vm._e(),
                        _vm.noticeList.length && _vm.showType == 3
                          ? _vm._l(_vm.noticeList, function (notice) {
                              return _c(
                                "span",
                                { key: notice.id, staticClass: "user-mod" },
                                [_vm._v("@" + _vm._s(notice.name))]
                              )
                            })
                          : _vm._e(),
                        _vm._l(_vm.receiptList, function (receipt) {
                          return [
                            _c(
                              "span",
                              {
                                key: receipt.id,
                                staticClass: "user-mod receipt-mod",
                              },
                              [_vm._v("@" + _vm._s(receipt.name))]
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "text-wrap" }, [
                      _c("textarea", {
                        ref: "releaseTextarea",
                        staticClass: "r-textarea",
                        attrs: {
                          maxlength: "500",
                          placeholder: "说点什么...",
                          rows: "3",
                          cols: "30",
                          autofocus: "",
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-foot" }, [
                    _c(
                      "div",
                      { staticClass: "foot-left" },
                      [
                        _c(
                          "en-upload",
                          {
                            staticClass: "foot-upload",
                            attrs: {
                              "on-change": _vm.handleOnChange,
                              limitNum: "9",
                              limitType: "3",
                            },
                          },
                          [
                            _c(
                              "en-button",
                              {
                                attrs: {
                                  icon: "iconfujianhetupian-kongjian",
                                  type: "text",
                                },
                              },
                              [_vm._v(" 图片 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom-start",
                              width: "400",
                              offset: 20,
                              "popper-class": "cus-popcss",
                              trigger: "click",
                            },
                          },
                          [
                            _c("en-tree", {
                              attrs: {
                                data: _vm.treeList,
                                "node-key": "id",
                                "highlight-current": "",
                                props: _vm.defaultProps,
                                singleSelect: true,
                              },
                              on: { "node-click": _vm.changeRelease },
                            }),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showType == 3,
                                    expression: "showType==3",
                                  },
                                ],
                                staticStyle: { overflow: "auto" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "输入关键字进行过滤" },
                                  model: {
                                    value: _vm.filterText,
                                    callback: function ($$v) {
                                      _vm.filterText = $$v
                                    },
                                    expression: "filterText",
                                  },
                                }),
                                _c("en-tree", {
                                  ref: "tree_dept",
                                  attrs: {
                                    data: _vm.treeData,
                                    "show-checkbox": "",
                                    "node-key": "id",
                                    lazy: "",
                                    load: _vm.loadNode,
                                    "highlight-current": "",
                                    props: _vm.defaultProps,
                                    "filter-text": _vm.filterText,
                                  },
                                  on: { "check-change": _vm.changeMulti },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "en-button",
                              {
                                staticClass: "foot-left-mod",
                                attrs: {
                                  slot: "reference",
                                  icon: "iconfabu-gongsiquan",
                                  type: "text",
                                },
                                slot: "reference",
                              },
                              [_vm._v(" 发布范围 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-popover",
                          {
                            ref: "pophz",
                            attrs: {
                              placement: "bottom-start",
                              width: "400",
                              offset: 20,
                              "popper-class": "cus-popcss",
                              trigger: "click",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "输入关键字进行过滤" },
                              model: {
                                value: _vm.filterText2,
                                callback: function ($$v) {
                                  _vm.filterText2 = $$v
                                },
                                expression: "filterText2",
                              },
                            }),
                            _c("en-tree", {
                              ref: "replyrange",
                              attrs: {
                                data: _vm.treeData,
                                "check-mode": "parent-children",
                                "show-checkbox": "",
                                "node-key": "id",
                                lazy: "",
                                load: _vm.loadNode,
                                "highlight-current": "",
                                props: _vm.defaultProps,
                                "filter-text": _vm.filterText2,
                              },
                              on: { "check-change": _vm.replyRangechange },
                            }),
                            _c(
                              "en-button",
                              {
                                staticClass: "foot-left-mod",
                                attrs: {
                                  slot: "reference",
                                  icon: "iconhuizhi-gongsiquan",
                                  type: "text",
                                },
                                slot: "reference",
                              },
                              [_vm._v(" 回执范围 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "foot-right" },
                      [
                        _c(
                          "en-button",
                          {
                            staticClass: "btn-width",
                            on: { click: _vm.handleSendTheme },
                          },
                          [_vm._v(" 发布 ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.imgList.length
                    ? _c(
                        "div",
                        { staticClass: "form-att atta-file-wrap" },
                        _vm._l(_vm.imgList, function (item, _ind) {
                          return _c("atta-img", {
                            key: item.code,
                            attrs: { imgdata: item },
                            on: {
                              delImg: () => {
                                _vm.deleteRow(_ind, _vm.imgList)
                              },
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.viewImage(_ind)
                              },
                            },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "list-wrap" },
            _vm._l(_vm.newsList, function (item) {
              return _c("list", {
                key: item.id,
                attrs: { listdata: item },
                on: {
                  delComment: _vm.delComment,
                  receiptSuccess: _vm.getNewNumber,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }