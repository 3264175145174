<!--
 * @Author: zjp
 * @Date: 2020-05-12 17:02:35
 * @LastEditTime: 2021-08-19 12:02:24
 * @LastEditors: wuqi
 * @Description: 查看回执
 * @FilePath: \user-company-circle\src\views\pop-record.vue
 -->

<template>
  <en-dialog
    class="pop-record-container"
    :visible.sync="popShowVisible"
    @close="closeHandler"
  >
    <div
      slot="title"
      style="text-align:left;height:30px;line-height:30px;"
    >
      <span>查看回执<span  v-if="list.length"> ({{`${readNum}/${list.length}`}})</span></span>
    </div>
    <div class="x-dialog-body">
      <div class="x-wrap" v-if="list.length">
        <div class="x-row" v-for="item in list" :key="item.id">
          <div class="name">
            <user-logo-Info
              :user-name="item.name"
              :user-id='item.objectId'
              :user-logo='item.url'
              class="user-cls"
              :is-show-circle="true"
              :logo-size="30"
            >
            </user-logo-Info>
          <div class="tit">{{item.name}}</div>
          </div>
          <div class="time">{{item.readFlag==='000'?'':getDateFormat(item.modifyTime)}}</div>

          <div class="status">
            <div class="bg" v-if="item.readFlag!=='000'">已阅</div>
          </div>
        </div>

      </div>
      <div  v-else>
        <en-result v-if="!loading" type="NoRecord"></en-result>
        <en-result v-else type="loading"></en-result>
      </div>

    </div>
  </en-dialog>
</template>
<script>
import dayJs from "dayjs";
import userLogoInfo from "@/components/user-logo-info";
import { companyCircleService } from "@/api";
// 引入用户详情弹窗组件
export default {
  name: "PopRecord",
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      required: false
    },
    themeId: {
      type: [String, Number]
    }
  },
  computed: {
    popShowVisible: {
      get() {
        return this.visible;
      },
      set(v) {
        this.$emit("update:visible", v);
      }
    },
    readNum() {
      return this.list.filter((item) => item.readFlag !== "000").length;
    }
  },
  watch: {
    popShowVisible(newVal) {
      if (newVal) {
        this.receiptRecord();
      }
    }
  },
  components: {
    userLogoInfo
  },
  data() {
    return {
      list: [],
      loading: false
    };
  },

  methods: {
    /**
     * @description: 格式化评论时间
     * @itm : 当前数据
     * @return:
     */
    getDateFormat(time) {
      return dayJs(time).format("YYYY-MM-DD HH:mm:ss");
    },
    closeHandler() {
      this.popShow = false;
      this.$emit("closeHandler");
    },
    async receiptRecord() {
      this.list = [];
      this.loading = true;
      const res = await companyCircleService.themeReceiptList({ themeId: this.themeId });
      if (Object.prototype.toString.call(res).slice(8, -1) === "Array") {
        this.list = res;
      } else {
        this.list = [];
      }
      this.loading = false;
    }
  }
};
</script>
<style lang='scss' scoped>
.pop-record-container {
  .x-dialog-body{
    padding-bottom: 20px;
    .x-wrap{
      padding:5px 10px;
      border: 1px solid #ecf1f7;
      border-radius: 3px;
      height: 61vh;
      overflow: auto;
      .x-row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-items: center;
        height: 50px;
        .name{
          display: flex;
          align-items: center;
          width:40%;
          .tit{
            font-size: 12px;
            color: #b2b5ba;
          }

          .user-cls{
            width: 30px;
            height: 30px;
            margin-right: 10px;
            cursor: pointer;
          }
        }
        .time{
            width:40%;
            font-size: 12px;
            color: #a5a8b0;
        }
        .status{
          width:100px;
          font-size: 12px;
          color: #333;
          .bg{
            width:50px;
            height: 25px;
            line-height: 25px;;
            background: #e9faf4;
            color:#78d5b6;
            border-radius: 2px;
            font-size: 12px;
            text-align: center;
          }
        }
      }
    }

  }
}
</style>
