<template>
  <div class="imod">
    <div class="in-icon-wrap">
      <userLogoInfo
        :user-id="rlist.author"
        :is-show-circle="true"
        :user-name="rlist.authorName"
        :user-logo="rlist.url"
        :logo-size="30"
      >
      </userLogoInfo>
    </div>
    <div class="in-info-wrap">
      <div class="in-tit-wrap" v-if="rlist.toNoticeName">
        <span>{{ rlist.authorName }}</span>
        <span style="color:#636c78;">：回复</span>
        {{ '@' + rlist.toNoticeName }}
        <span style="color:#636c78;">：{{ rlist.content }}</span>
      </div>
      <div class="in-tit-wrap" v-else>
        <span>{{ rlist.authorName }}</span>
        <span style="color:#636c78;">：{{ rlist.content }}</span>
      </div>
      <div class="in-time-wrap">
        <div class="in-time-inner">
          {{ getFormatTime(rlist.createTime) }}
        </div>
      </div>
      <div class="in-oper-wrap">
        <span
          v-if="isSelfReply"
          class="btn-del"
          @click="delReply"
        >删除</span>
        <span
          class="btn-reply"
          @click="reply"
        >回复</span>
      </div>
    </div>
    <div class='reply-box'>
      <replyForm
          v-if="isShowTextArea"
          :themeId='rlist.themeId'
          :author="rlist.author"
          :type="'001'"
          @replySuccess="replySuccess"
          ref="rform"
          class="apl80"
          :placeholder="this.placeHolder"
        ></replyForm>
    </div>

  </div>
</template>
<script>
// import { mapGetters } from "vuex";
import userLogoInfo from "@/components/user-logo-info";
import companyComm from "@/mixins/companyCircle/companyComm";// 公共方法
import replyForm from "@/views/companyCircle/reply-form"; // 引入回复面板组件
import { companyCircleService } from "@/api";
// 引入回复组件
export default {
  name: "Reply",
  components: { userLogoInfo, replyForm },
  mixins: [companyComm],
  props: {
    // 一条回复数据
    rlist: {
      type: Object,
      required: true
    }
  },
  computed: {
    isSelfReply() {
      return this.rlist.author === this.$store.state.userInfo.userId;
    }
  },
  data() {
    return {
      isShowTextArea: false
    };
  },
  methods: {
    replySuccess(res) {
      this.$emit("replySuccess", res);
      this.isShowTextArea = false;
    },
    // 删除一条回复方法
    delReply() {
      this.$confirm("确认删除此条评论?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async() => {
        const res = await companyCircleService.deleteThemeReply({ replyId: this.rlist.id });
        console.log("删除成功", res);
        this.$emit("delReply", this.rlist.id);
        this.$message({
          type: "success",
          message: "删除成功!"
        });
      });
    },
    // 回复评论方法
    reply() {
      this.isShowTextArea = true;
      this.placeHolder = `回复：@${this.rlist.authorName}`;
    }
  }
};
</script>
<style lang='scss' scoped>
.imod {
  position: relative;
  padding-top: 15px;
  padding-bottom: 10px;
  border-top: 1px solid #ecf1f7;
  min-height: 72px;
  .in-icon-wrap {
    position: absolute;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .in-info-wrap {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 40px;
    width: 80%;
    .in-tit-wrap {
      font-size: 12px;
      color: #3e90fe;
    }
    .in-time-wrap {
      font-size: 12px;
      color: #91a1b7;
      padding-top: 15px;
      .in-time-inner {
        float: left;
      }
    }
  }
  .reply-box{
    padding-left:34px;
  }
  .in-oper-wrap {
    position: absolute;
    top: 20px;
    right: 20px;
    visibility: hidden;
    span {
      display: inline-block;
      height: 20px;
      line-height: 20px;
      width: 30px;
      font-size: 12px;
    }
    .btn-del {
      color: #f76b6b;
      cursor: pointer;
    }
    .btn-reply {
      color: #636c78;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  &:hover{
    .in-oper-wrap{
      visibility:unset;
    }
  }
}
</style>;
