<template>
  <div class="mod">
    <div class="icon-wrap">
      <userLogoInfo
        :user-id="listdata.author"
        :is-show-circle="true"
        :user-name='listdata.displayName'
        :user-logo='listdata.url'
      >
      </userLogoInfo>
    </div>
    <div class="info-wrap">
      <div class="tit-wrap">
        <span>{{ listdata.displayName }}</span>
      </div>
      <div class="mess-wrap">
        <div class="mess-info">
          {{ listdata.content }}
          <attachment
            v-if="listdata.themeFileList&&listdata.themeFileList.length"
            class="acolor"
            :img-list="listdata.themeFileList"
          >
          </attachment>
        </div>

      </div>
      <div class="time-wrap">
        <div class="time-inner">
          {{ getFormatTime(listdata.createTime) }}
        </div>
        <div class="oper-inner">
          <!-- 提交回执 -->
          <div class="oper-btn" v-if="listdata.isHandleReceipt==='000'">
            <i class="icon-btn" @click="toReceipt" >
              <en-icon name="huizhi-gongsiquan" size="16" > </en-icon>
            </i>
          </div>
          <!-- 点赞 -->
          <div class="oper-btn" :class="{'selflike':selfLike}" @click="toGiveLike" >
            <i class="icon-btn" >
              <en-icon  name="dianzan-gongsiquan" size="16" >  </en-icon>
            </i>
            <div class="num">
              {{ listdata.likeCount }}
            </div>
          </div>
          <!-- 评论 -->
          <div class="oper-btn">
            <i class="icon-btn" @click="toReply" >
              <en-icon  name="pinglun-gongsiquan" size="16" > </en-icon>
            </i>
            <div class="num">
              {{ listdata.themeReplyList.length }}
            </div>
          </div>

          <!-- 收起&展开 -->
          <div class="oper-btn">
            <i class="icon-btn" @click="hideReply" >
              <en-icon :name="this.isShowIcon" size="16" > </en-icon>
            </i>
          </div>

        </div>
      </div>
    </div>
    <transition name="el-fade-in">
      <div v-if="this.isShowReply"  class="info-more" >
        <div class="give-like-wrap" v-if="likeList.length">
          <div class="user-give-like">
            <userLogoInfo
              v-for="item in likeList"
              :key="item.author"
              class="user-cls"
              :user-id="item.author"
              :user-name='item.displayName'
              :user-logo="item.url"
              :logo-size='30'
              :is-show-circle="true"
            >
            </userLogoInfo>
          </div>
          <div v-show="this.ishowGiveMore" class="give-like-more" @click="toGiveLikeMore" >
            <div class="give-like-more-inner">
              <en-icon name="gengduotupian-gongsiquan" size="16" > </en-icon>
            </div>
          </div>
        </div>

        <reply
          v-for="item in replyList"
          :key="item.id"
          class="mgl80"
          :rlist="item"
          @delReply="delReply"
          @replySuccess="replySuccess"
        >
        </reply>
        <div
          v-if="!!leaveNum"
          class="more-wrap"
          @click="setMore"
        >
          查看更多 ({{ this.leaveNum }})
        </div>
        <replyForm
          v-if="isShowTextArea"
          :type="'000'"
          :themeId='listdata.id'
          :author="listdata.author"
          @replySuccess="replySuccess"
          ref="rform"
          class="apl80"
          :placeholder="this.placeHolder"
        ></replyForm>
      </div>
    </transition>
    <div class="show-receipt" v-if="listdata.author===userInfo.userId">
      <popReceipt
        :listdata="this.listdata"
        @delComment="delComment"
        @receiptRecord="receiptRecord"
      >
        <en-icon
          name="gengduo-liebiaoyouce"
          size="12"
          color="#A9B5C6"
        >
        </en-icon>
      </popReceipt>
    </div>
    <popRecord
      :visible.sync="recordVisible"
      :themeId='listdata.id'
    ></popRecord>
  </div>
</template>
<script>
// import { mapGetters } from "vuex";
import attachment from "@/views/companyCircle/attachment"; // 引入附件组件
import reply from "@/views/companyCircle/reply"; // 引入回复组件
import replyForm from "@/views/companyCircle/reply-form"; // 引入回复面板组件
import userLogoInfo from "@/components/user-logo-info"; // 引入用户详情弹窗组件
import popReceipt from "@/views/companyCircle/pop-receipt"; // 引入回执删除弹窗组件
import popRecord from "@/views/companyCircle/pop-record";
import companyComm from "@/mixins/companyCircle/companyComm";// 公共方法
import { companyCircleService } from "@/api";

export default {
  name: "List",
  components: {
    attachment, reply, replyForm, userLogoInfo, popReceipt, popRecord
  },
  props: {
    // 一条公司圈信息数据
    listdata: {
      type: Object,
      required: true
    }
  },
  mixins: [companyComm],
  data() {
    return {
      replyAuthor: "", // 当前被评论/回复的对象id
      isShowReply: false, // 是否显示评论回复内容
      isShowIcon: "zhankai-gongsiquan", // 评论回复内容展开/收起
      isShowMore: false, // 是否显示更多评论回复
      isClickShowMore: false, // 触发评论更多按钮状态
      isShowTextArea: false, // 是否显示回复文本域
      placeHolder: "说点什么...", // 回复placeholder
      moreNum: 3, // 最多显示评论回复数量
      giveLikeNum: 5, // 最多显示点赞数量
      recordVisible: false, // 是否显示查看回执弹窗
      receiptList: [] // 回执列表
    };
  },

  computed: {
    userInfo() { // 个人信息
      return this.$store.state.userInfo;
    },
    likeList() { // 点赞列表
      return this.listdata.likeList.slice(0, this.giveLikeNum);
    },
    selfLike() { // 自己是否点赞
      return this.likeList.some((item) => item.author === this.userInfo.userId);
    },
    replyList() { // 评论回复列表
      return this.listdata.themeReplyList.slice(0, this.moreNum);
    },
    leaveNum() { // 剩余评论回复数量
      return this.listdata.themeReplyList.length - this.replyList.length;
    },
    ishowGiveMore() { // 是否显示更多点赞按钮
      return this.listdata.likeList.length > this.giveLikeNum;
    }
  },
  methods: {

    // 提交回执
    async toReceipt() {
      await companyCircleService.receiptToRead({ themeId: this.listdata.id });
      this.listdata.isHandleReceipt = "001";
      this.$emit("receiptSuccess");
      this.$message.success("已回执成功");
    },
    // 提交点赞
    async toGiveLike() {
      const type = this.selfLike ? "001" : "000";
      await companyCircleService.likeTheme({ themeId: this.listdata.id, type });
      if (type === "001") { // 取消点赞
        const selfIndex = this.listdata.likeList.findIndex((item) => item.author === this.$store.state.userInfo.userId);
        this.listdata.likeList.splice(selfIndex, 1);
        this.listdata.likeCount--;
      } else { // 点赞
        this.listdata.likeList.push({
          author: this.$store.state.userInfo.userId,
          displayName: this.$store.state.userInfo.name,
          url: this.$store.state.userInfo.userLogo
        });
        this.listdata.likeCount++;
      }
    },
    // 评论成功
    replySuccess(res) {
      this.listdata.themeReplyList.push(res);
      this.setMore();
    },
    // 点击评论
    toReply() {
      this.isShowIcon = "zhankai-gongsiquan";
      this.isShowReply = true;
      this.isShowTextArea = true;
      this.placeHolder = "说点什么...";
      this.$nextTick(() => {
        this.$refs.rform.focusText();
      });
    },
    // 删除评论
    delComment(id) {
      this.$emit("delComment", id);
    },
    // 删除回复
    delReply(id) {
      const index = this.listdata.themeReplyList.findIndex((item) => item.id === id);
      this.listdata.themeReplyList.splice(index, 1);
    },
    // 点击回复评论
    handleReply(replyItem) {
      console.log(replyItem);
      this.isShowTextArea = true;
      this.placeHolder = "@某人";
      this.$refs.rform.focusText();
    },
    // 加载更多
    setMore() {
      this.moreNum = this.listdata.themeReplyList.length;
      // this.moreNum += 3;
    },
    // 加载更多点赞信息
    toGiveLikeMore() {
      this.giveLikeNum = this.listdata.likeList.length;
    },
    // 收起、展开回复
    hideReply() {
      if (this.isShowIcon === "shouqi-gongsiquan") {
        this.isShowIcon = "zhankai-gongsiquan";
      } else {
        this.isShowIcon = "shouqi-gongsiquan";
      }
      this.isShowReply = !this.isShowReply;
    },

    /**
     * @description:查看回执弹窗隐藏
     */
    closeRecord() {
      this.recordVisible = false;
    },
    /**
     * @description:查看回执弹窗显示
     */
    async receiptRecord(themeId) {
      console.log(themeId);
      this.receiptList = [];
      const res = await companyCircleService.themeReceiptList({ themeId });
      if (Object.prototype.toString.call(res).slice(8, -1) === "Array") {
        this.receiptList = res;
      } else {
        this.receiptList = [];
      }
      this.recordVisible = true;
    }
  }
};
</script>
<style lang='scss' scoped>
.mod {
  position: relative;
  padding-top: 24px;

  border-bottom: 1px solid #ecf1f7;
  min-height: 110px;
  .icon-wrap {
    position: absolute;
    width: 80px;
    height: 80px;
    cursor: pointer;
  }
  .info-wrap {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 80px;
    padding-bottom: 10px;
    .tit-wrap {
      font-size: 14px;
      color: #3e90fe;
    }
    .mess-wrap {
      font-size: 12px;
      color: #636c78;
      padding-top: 5px;
      overflow-wrap: break-word;
      .mess-info {
        line-height: 20px;
        width: 95%;
        text-align: left;
      }
    }
    .time-wrap {
      font-size: 12px;
      color: #91a1b7;
      padding-top: 15px;
      .time-inner {
        float: left;
      }
      .oper-inner {
        float: right;
        display: flex;
        .oper-btn {
          padding-left: 10px;
          cursor: pointer;
          .icon-btn {
            display: inline-block;
            vertical-align: middle;
          }
          .num {
            display: inline-block;
            vertical-align: middle;
            font-size: 12px;
            padding-left: 2px;
          }
           &:hover{
              color: #179eda;
            }
        }
        .selflike{
          color: #179eda;
        }
      }
    }
  }
  .more-wrap {
    height: 36px;
    line-height: 36px;
    vertical-align: middle;
    text-align: left;
    padding-left: 80px;
    font-size: 12px;
    color: #3e90fe;
    cursor: pointer;
  }
  .acolor {
    background-color: #fff;
    padding-top: 15px;
  }
  .apl80 {
    padding-left: 80px;
  }
  .mgl80 {
    margin-left: 80px;
  }
  .wh {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .give-like-wrap {
    padding: 0px 20px 10px 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .user-give-like {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
      .user-cls {
        width: 30px;
        height: 30px;
        margin-left: 18px;
        cursor: pointer;
        &:hover{
          transform: rotate(360deg);
          transition: all 500ms ease-in-out;
        }
      }

    }
    .give-like-more {
      width: 30px;
      margin-left: 18px;
      cursor: pointer;
      .give-like-more-inner {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #a9b5c6;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &:hover{
        transform: rotate(360deg);
        transition: all 500ms ease-in-out;
      }
    }
  }
  .show-receipt {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
</style>;
