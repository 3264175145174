var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "new-theme-list" }, [
    _c(
      "div",
      { staticClass: "header-wrap" },
      [
        _c("en-icon", {
          staticClass: "back-icon",
          attrs: { name: "icontupian-zuojiantou", size: "small" },
          nativeOn: {
            click: function ($event) {
              return _vm.goBack.apply(null, arguments)
            },
          },
        }),
        _vm._v(" 查看消息 "),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "scroll",
            rawName: "v-scroll",
            value: _vm.getCommentList,
            expression: "getCommentList",
          },
        ],
        staticClass: "list-wrap",
      },
      _vm._l(_vm.newsList, function (item) {
        return _c("list", {
          key: item.id,
          attrs: { listdata: item },
          on: { delComment: _vm.delComment, receiptSuccess: _vm.getNewNumber },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }