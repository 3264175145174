var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "reply-wrap" }, [
    _c("div", { staticClass: "reply-text" }, [
      _c("textarea", {
        ref: `reply-${_vm.themeId}`,
        staticClass: "re-textarea",
        attrs: {
          maxlength: "100",
          placeholder: this.placeholder,
          rows: "2",
          cols: "30",
          autofocus: "",
        },
      }),
    ]),
    _c("div", { staticClass: "reply-foot" }, [
      _c(
        "div",
        { staticClass: "reply-foot-right" },
        [
          _c("en-button", { on: { click: _vm.toReplyTheme } }, [
            _vm._v(_vm._s(_vm.type == "000" ? "评论" : "回复")),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }