<!--
 * @Description: 公司圈回复面板组件
 * @Author: zoujp
 * @Date: 2019-09-30 12:06:21
 * @LastEditTime: 2021-08-19 11:18:38
 * @LastEditors: wuqi
 -->
<template>
  <div class="reply-wrap">
    <div class="reply-text">
      <textarea
        :ref="`reply-${themeId}`"
        class="re-textarea"
        maxlength="100"
        :placeholder="this.placeholder"
        rows="2"
        cols="30"
        autofocus
      ></textarea>
    </div>
    <div class="reply-foot">
      <!-- <div class="reply-foot-left">
        <en-icon
          class="icon-padding"
          name="biaoqing-gongsiquan"
          size="16"
          title="表情"
        >
        </en-icon>
        <en-icon
          class="icon-padding"
          name="-gongsiquan"
          size="16"
          title="@谁"
        >
        </en-icon>
        <en-upload :options="this.uploadParams">
          <en-icon
            class="icon-padding"
            name="fujianhetupian-kongjian"
            size="16"
            title="附件"
          >
          </en-icon>
        </en-upload>
      </div> -->
      <div class="reply-foot-right">
        <en-button @click="toReplyTheme">{{type=='000'?'评论':'回复'}}</en-button>
      </div>
    </div>
  </div>
</template>
<script>
import { companyCircleService } from "@/api";

export default {
  name: "ReplyForm",
  components: { },
  props: {
    placeholder: {
      type: String
    },
    type: {
      type: String,
      default: "000"
    },
    themeId: {
      type: [String, Number]
    },
    author: {
      type: [String, Number]
    }
  },
  data() {
    return { uploadParams: {} };
  },
  methods: {
    async toReplyTheme() {
      const replyContent = this.$refs[`reply-${this.themeId}`].value;
      if (replyContent === "") {
        this.$message({
          type: "warning",
          message: `${this.type === "000" ? "评论" : "回复"}内容不能为空`
        });
        return;
      }
      const submitData = {
        themeId: this.themeId,
        type: this.type,
        author: this.author,
        content: replyContent
      };
      const res = await companyCircleService.replyTheme(submitData);
      this.$nextTick(() => {
        this.$refs[`reply-${this.themeId}`].value = "";
      });
      this.$emit("replySuccess", res);
    },
    // 自动获取光标方法
    focusText() {
      this.$refs[`reply-${this.themeId}`].focus();
    }
  }
};
</script>
<style lang='scss' scoped>
.reply-wrap {
  position: relative;
  padding-top: 10px;
  .reply-text {
    width: 98%;
    .re-textarea {
      width: 100%;
      resize: none;
      border-radius: 5px;
      border: 1px solid #e8ecf2;
      font-size: 12px;
      padding-left: 10px;
      padding-top: 5px;
      color: #333333;
      outline: none;
    }
  }
  .reply-foot {
    width: 98%;
    height: 40px;
    text-align: center;
    padding-top: 7px;
    margin-bottom: 7px;
    .reply-foot-left {
      float: left;
      .icon-padding {
        margin-right: 16px;
        color: #a9b5c6;
        cursor: pointer;
      }
    }
    .reply-foot-right {
      float: right;
    }
  }
}
</style>;
  et
