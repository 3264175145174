<!--
 * @Author: your name
 * @Date: 2020-06-10 10:49:24
 * @LastEditTime: 2021-08-13 14:00:47
 * @LastEditors: wuqi
 * @Description: In User Settings Edit
 * @FilePath: \user\src\views\companyCircle\atta-img.vue
-->
<template>
  <div>
    <span class="img-wrap">
      <el-image
        :src="cleImg.url||cleImg.code||cleImg.filePath"
        fit="cover"
        class="img wh"
        :title="cleImg.name"
        lazy
      ></el-image>
      <span
        class="del"
        v-if="deleteabled"
        @click="delFile"
      >
        <en-icon
          name="shanchu-liebiao"
          size="small"
        >
        </en-icon>
      </span>
    </span>
  </div>
</template>
<script>
export default {
  name: "AttaImg",
  props: {
    // 图片数据
    imgdata: {
      type: Object,
      required: true
    },
    deleteabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      cleImg: JSON.parse(JSON.stringify(this.imgdata))
    };
  },
  methods: {
    // 删除一个图片方法
    delFile() {
      this.$emit("delImg", this.cleImg.id);
    }
  }
};
</script>
<style lang='scss' scoped>
.img-wrap {
  display: block;
  float: left;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  margin-right: 20px;
  position: relative;
  &:hover {
    .del {
      display: block;
    }
  }
  .wh {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 1px solid transparent;
  }
  .del {
    color: #f54646;
    position: absolute;
    top: -7px;
    right: -7px;
    display: none;
    z-index: 2;
    cursor: pointer;
  }
}
</style>;
