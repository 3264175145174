var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mod" },
    [
      _c(
        "div",
        { staticClass: "icon-wrap" },
        [
          _c("userLogoInfo", {
            attrs: {
              "user-id": _vm.listdata.author,
              "is-show-circle": true,
              "user-name": _vm.listdata.displayName,
              "user-logo": _vm.listdata.url,
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "info-wrap" }, [
        _c("div", { staticClass: "tit-wrap" }, [
          _c("span", [_vm._v(_vm._s(_vm.listdata.displayName))]),
        ]),
        _c("div", { staticClass: "mess-wrap" }, [
          _c(
            "div",
            { staticClass: "mess-info" },
            [
              _vm._v(" " + _vm._s(_vm.listdata.content) + " "),
              _vm.listdata.themeFileList && _vm.listdata.themeFileList.length
                ? _c("attachment", {
                    staticClass: "acolor",
                    attrs: { "img-list": _vm.listdata.themeFileList },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "time-wrap" }, [
          _c("div", { staticClass: "time-inner" }, [
            _vm._v(
              " " + _vm._s(_vm.getFormatTime(_vm.listdata.createTime)) + " "
            ),
          ]),
          _c("div", { staticClass: "oper-inner" }, [
            _vm.listdata.isHandleReceipt === "000"
              ? _c("div", { staticClass: "oper-btn" }, [
                  _c(
                    "i",
                    { staticClass: "icon-btn", on: { click: _vm.toReceipt } },
                    [
                      _c("en-icon", {
                        attrs: { name: "huizhi-gongsiquan", size: "16" },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "oper-btn",
                class: { selflike: _vm.selfLike },
                on: { click: _vm.toGiveLike },
              },
              [
                _c(
                  "i",
                  { staticClass: "icon-btn" },
                  [
                    _c("en-icon", {
                      attrs: { name: "dianzan-gongsiquan", size: "16" },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "num" }, [
                  _vm._v(" " + _vm._s(_vm.listdata.likeCount) + " "),
                ]),
              ]
            ),
            _c("div", { staticClass: "oper-btn" }, [
              _c(
                "i",
                { staticClass: "icon-btn", on: { click: _vm.toReply } },
                [
                  _c("en-icon", {
                    attrs: { name: "pinglun-gongsiquan", size: "16" },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "num" }, [
                _vm._v(" " + _vm._s(_vm.listdata.themeReplyList.length) + " "),
              ]),
            ]),
            _c("div", { staticClass: "oper-btn" }, [
              _c(
                "i",
                { staticClass: "icon-btn", on: { click: _vm.hideReply } },
                [
                  _c("en-icon", {
                    attrs: { name: this.isShowIcon, size: "16" },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c("transition", { attrs: { name: "el-fade-in" } }, [
        this.isShowReply
          ? _c(
              "div",
              { staticClass: "info-more" },
              [
                _vm.likeList.length
                  ? _c("div", { staticClass: "give-like-wrap" }, [
                      _c(
                        "div",
                        { staticClass: "user-give-like" },
                        _vm._l(_vm.likeList, function (item) {
                          return _c("userLogoInfo", {
                            key: item.author,
                            staticClass: "user-cls",
                            attrs: {
                              "user-id": item.author,
                              "user-name": item.displayName,
                              "user-logo": item.url,
                              "logo-size": 30,
                              "is-show-circle": true,
                            },
                          })
                        }),
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: this.ishowGiveMore,
                              expression: "this.ishowGiveMore",
                            },
                          ],
                          staticClass: "give-like-more",
                          on: { click: _vm.toGiveLikeMore },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "give-like-more-inner" },
                            [
                              _c("en-icon", {
                                attrs: {
                                  name: "gengduotupian-gongsiquan",
                                  size: "16",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._l(_vm.replyList, function (item) {
                  return _c("reply", {
                    key: item.id,
                    staticClass: "mgl80",
                    attrs: { rlist: item },
                    on: {
                      delReply: _vm.delReply,
                      replySuccess: _vm.replySuccess,
                    },
                  })
                }),
                !!_vm.leaveNum
                  ? _c(
                      "div",
                      { staticClass: "more-wrap", on: { click: _vm.setMore } },
                      [_vm._v(" 查看更多 (" + _vm._s(this.leaveNum) + ") ")]
                    )
                  : _vm._e(),
                _vm.isShowTextArea
                  ? _c("replyForm", {
                      ref: "rform",
                      staticClass: "apl80",
                      attrs: {
                        type: "000",
                        themeId: _vm.listdata.id,
                        author: _vm.listdata.author,
                        placeholder: this.placeHolder,
                      },
                      on: { replySuccess: _vm.replySuccess },
                    })
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
      ]),
      _vm.listdata.author === _vm.userInfo.userId
        ? _c(
            "div",
            { staticClass: "show-receipt" },
            [
              _c(
                "popReceipt",
                {
                  attrs: { listdata: this.listdata },
                  on: {
                    delComment: _vm.delComment,
                    receiptRecord: _vm.receiptRecord,
                  },
                },
                [
                  _c("en-icon", {
                    attrs: {
                      name: "gengduo-liebiaoyouce",
                      size: "12",
                      color: "#A9B5C6",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("popRecord", {
        attrs: { visible: _vm.recordVisible, themeId: _vm.listdata.id },
        on: {
          "update:visible": function ($event) {
            _vm.recordVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }