import store from "@/store";
import BaseService from "../BaseService";

class Team extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  /**
   * 创建群组
   * @param userList 用户信息列表
   * @param isApproval 是否审批相关 true/false
   * @returns {*}
   */
  createTeam(userList = [], isApproval) {
    console.log(userList);
    const { http } = this;
    if (userList.length > 1) {
      const userInfo = store.state.userInfo;
      const selfId = userInfo?.imUserId;
      const isExist = userList.some((item) => item.imUserId === selfId);
      if (!isExist) { // 把自己加上去
        userList.push(userInfo);
      }
    }
    userList = userList.map((item) => item.imUserId || item.id);
    const sessionType = userList.length > 1 ? "001" : "000";// 会话类型 000单聊天 001群聊天
    isApproval = isApproval ? "001" : "000";// 是否与审批相关 000-与审批无关  001-与审批有关
    return http.post("addGroupAndMember", { userList: JSON.stringify(userList), sessionType, isApproval });
  }

  chatRecordSearch(keys, session) {
    const { http } = this;
    const searchObj = {
      sessionId: session.id,
      sessionType: session.scene === "p2p" ? "0" : "1",
      nowTotal: session.nt,
      timeStamp: session.ts,
      searchText: keys,
      type: 0,
      pageNo: 1,
      pageSize: 100,
      totalPageSize: ""
    };
    return http.post("chatRecordSearch", searchObj);
  }

  /**
   * @description: 获取系统用户信息
   * @param {*} params
   * @return {*}
   */
  queryUserInfo(params) {
    const { http } = this;
    return http.post("queryUserInfo", params);
  }

  /**
   * @description: 获取群成员列表
   * @param {*} params
   * @return {*}
   */
   getGroupUserList(params) {
    const { http } = this;
    return http.post("getGroupUserList", params);
  }

  /**
   * @description: 新增群成员
   * @param {*} params
   * @return {*}
   */
  addGroupMember(params) {
    const { http } = this;
    return http.post("addGroupMember", params);
  }

  /**
   * @description:  //获取到了会话列表
   * @param {*} params
   * @return {*}
   */
   chatQueryGroupList(params) {
    const { http } = this;
    return http.post("queryGroupList", params);
  }

  /**
   * @description: 获取按钮信息
   * @param {*} params
   * @return {*}
   */
  getBillInfo(params) {
    const { http } = this;
    return http.post("getBillInfo", params);
  }

  deleteMemberKick(params) {
    const { http } = this;
    return http.post("kick", params);
  }

  deleteMemberLeave(params) {
    const { http } = this;
    return http.post("leave", params);
  }
}

export default Team;
