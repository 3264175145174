<!--
 * @Description: 上传附件组件
 * @Author: zoujp
 * @Date: 2019-10-10 15:22:42
 * @LastEditTime: 2020-06-16 14:52:43
 * @LastEditors: pengyu
 -->
<template>
  <el-upload
    class="upload-demo"
    :options="[]"
    :action="eUrl"
    :data="eData"
    :multiple="eMultiple"
    :limit="eLimit"
    :on-exceed="handleExceed"
    :on-success="handleSuccess"
    :before-upload="handleBeforeUpload"
  >
    <slot>
      <el-button
        size="small"
        type="primary"
      >
        点击上传
      </el-button>
    </slot>
  </el-upload>
</template>
<script>
// options 参数如下：
// {
//     url:url //上传的url路径 可不传
//     types:["jpg","png","doc"],//不传或[]表示不限制
//     limit:10,//每次最多上传数量
//     size:2   //单位Mb，单个上传文件大小
//     multiple:true or false //默认多选
//     data:{} //上传额外带的参数
//     onSuccess:onSuccess //上传成功回调方法 可不传
// }
export default {
  name: "Upload",
  components: { },
  props: { options: { type: Object, required: true } },
  data() {
    return {};
  },
  computed: {
    // 额外上传参数
    eData() {
      return this.options.data || {};
    },
    // 文件是否多选
    eMultiple() {
      if (typeof this.options.multiple === "undefined") {
        return true;
      }
      return this.options.multiple;
    },
    // 文件限制数量
    eLimit() {
      return this.options.limit || 10;
    },
    // 上传服务器Url
    eUrl() {
      return this.options.url || "";
    }
  },
  methods: {
    // 上传前校验
    /**
     * @description:
     * @param {type}
     * @return:
     */
    handleBeforeUpload() {
      // var suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const types = this.options.types || [];
      if (types.length) {
        if (types.indexOf() > -1) {
          return true;
        }
        this.$message({
          message: `上传文件只支持${types.join("、")}格式!`,
          type: "warning"
        });
        return false;
      }
      return true;
    },
    // 上传校验方法
    handleExceed() {
      this.$message.warning(`一次最多上传${this.eLimit}个文件`);
    },
    // 上传成功回调方法
    handleSuccess(response, file, fileList) {
      const succ = this.options.onSuccess;
      succ && succ(response, file, fileList);
    }
  }
};
</script>
<style >
</style>
