var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "imod" }, [
    _c(
      "div",
      { staticClass: "in-icon-wrap" },
      [
        _c("userLogoInfo", {
          attrs: {
            "user-id": _vm.rlist.author,
            "is-show-circle": true,
            "user-name": _vm.rlist.authorName,
            "user-logo": _vm.rlist.url,
            "logo-size": 30,
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "in-info-wrap" }, [
      _vm.rlist.toNoticeName
        ? _c("div", { staticClass: "in-tit-wrap" }, [
            _c("span", [_vm._v(_vm._s(_vm.rlist.authorName))]),
            _c("span", { staticStyle: { color: "#636c78" } }, [
              _vm._v("：回复"),
            ]),
            _vm._v(" " + _vm._s("@" + _vm.rlist.toNoticeName) + " "),
            _c("span", { staticStyle: { color: "#636c78" } }, [
              _vm._v("：" + _vm._s(_vm.rlist.content)),
            ]),
          ])
        : _c("div", { staticClass: "in-tit-wrap" }, [
            _c("span", [_vm._v(_vm._s(_vm.rlist.authorName))]),
            _c("span", { staticStyle: { color: "#636c78" } }, [
              _vm._v("：" + _vm._s(_vm.rlist.content)),
            ]),
          ]),
      _c("div", { staticClass: "in-time-wrap" }, [
        _c("div", { staticClass: "in-time-inner" }, [
          _vm._v(" " + _vm._s(_vm.getFormatTime(_vm.rlist.createTime)) + " "),
        ]),
      ]),
      _c("div", { staticClass: "in-oper-wrap" }, [
        _vm.isSelfReply
          ? _c(
              "span",
              { staticClass: "btn-del", on: { click: _vm.delReply } },
              [_vm._v("删除")]
            )
          : _vm._e(),
        _c("span", { staticClass: "btn-reply", on: { click: _vm.reply } }, [
          _vm._v("回复"),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "reply-box" },
      [
        _vm.isShowTextArea
          ? _c("replyForm", {
              ref: "rform",
              staticClass: "apl80",
              attrs: {
                themeId: _vm.rlist.themeId,
                author: _vm.rlist.author,
                type: "001",
                placeholder: this.placeHolder,
              },
              on: { replySuccess: _vm.replySuccess },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }