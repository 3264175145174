var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      staticClass: "pop-cls",
      attrs: { placement: "bottom", width: "110", trigger: "click" },
      model: {
        value: _vm.showPopover,
        callback: function ($$v) {
          _vm.showPopover = $$v
        },
        expression: "showPopover",
      },
    },
    [
      _c("ul", { staticClass: "tem-list" }, [
        _vm.listdata.isReceiptList === "000"
          ? _c(
              "li",
              { staticClass: "btn-see", on: { click: _vm.seeReceipt } },
              [_vm._v(" 查看回执 ")]
            )
          : _vm._e(),
        _vm.listdata.isDelete === "000"
          ? _c("li", { staticClass: "btn-del", on: { click: _vm.del } }, [
              _vm._v(" 删除 "),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { attrs: { slot: "reference" }, slot: "reference" },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }