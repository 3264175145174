<!--
 * @Description: 查看回执弹窗组件
 * @Author: zoujp
 * @Date: 2019-10-11 21:46:48
 * @LastEditTime: 2020-12-02 09:28:05
 -->
<template>
  <el-popover
    class="pop-cls"
    placement="bottom"
    width="110"
    trigger="click"
    v-model="showPopover"
  >
    <ul class="tem-list">
      <li
        v-if="listdata.isReceiptList==='000'"
        class="btn-see"
        @click="seeReceipt"
      >
        查看回执
      </li>
      <li
        v-if="listdata.isDelete==='000'"
        class="btn-del"
        @click="del"
      >
        删除
      </li>
    </ul>
    <div slot="reference">
      <slot></slot>
    </div>
  </el-popover>
</template>
<script>
export default {
  name: "PopReceipt",
  components: { },
  props: {
    // 一条回复数据
    listdata: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showPopover: false
    };
  },
  methods: {
    // 查看回执方法
    seeReceipt() {
      this.showPopover = false;
      this.$emit("receiptRecord", this.listdata.id);
    },
    // 删除一条公司圈信息方法
    del() {
      this.showPopover = false;
      this.$emit("delComment", this.listdata.id);
    }
  }
};
</script>
<style >
.el-popover {
  min-width: 110px;
  padding: 0;
}
</style>
<style lang='scss' scoped>
.tem-list {
  list-style-type: none;
  padding: 5px 0 5px 0;
  margin: 0;
  font-size: 12px;
  color: #333333;
  li {
    padding: 5px 0 5px 10px;
    &:hover {
      color: #3e90fe;
      background-color: #f5f8fc;
      cursor: pointer;
    }
  }
}
</style>
