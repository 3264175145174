var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "pop-record-container",
      attrs: { visible: _vm.popShowVisible },
      on: {
        "update:visible": function ($event) {
          _vm.popShowVisible = $event
        },
        close: _vm.closeHandler,
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "text-align": "left",
            height: "30px",
            "line-height": "30px",
          },
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("span", [
            _vm._v("查看回执"),
            _vm.list.length
              ? _c("span", [
                  _vm._v(
                    " (" + _vm._s(`${_vm.readNum}/${_vm.list.length}`) + ")"
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _c("div", { staticClass: "x-dialog-body" }, [
        _vm.list.length
          ? _c(
              "div",
              { staticClass: "x-wrap" },
              _vm._l(_vm.list, function (item) {
                return _c("div", { key: item.id, staticClass: "x-row" }, [
                  _c(
                    "div",
                    { staticClass: "name" },
                    [
                      _c("user-logo-Info", {
                        staticClass: "user-cls",
                        attrs: {
                          "user-name": item.name,
                          "user-id": item.objectId,
                          "user-logo": item.url,
                          "is-show-circle": true,
                          "logo-size": 30,
                        },
                      }),
                      _c("div", { staticClass: "tit" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "time" }, [
                    _vm._v(
                      _vm._s(
                        item.readFlag === "000"
                          ? ""
                          : _vm.getDateFormat(item.modifyTime)
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "status" }, [
                    item.readFlag !== "000"
                      ? _c("div", { staticClass: "bg" }, [_vm._v("已阅")])
                      : _vm._e(),
                  ]),
                ])
              }),
              0
            )
          : _c(
              "div",
              [
                !_vm.loading
                  ? _c("en-result", { attrs: { type: "NoRecord" } })
                  : _c("en-result", { attrs: { type: "loading" } }),
              ],
              1
            ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }