var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-upload",
    {
      staticClass: "upload-demo",
      attrs: {
        options: [],
        action: _vm.eUrl,
        data: _vm.eData,
        multiple: _vm.eMultiple,
        limit: _vm.eLimit,
        "on-exceed": _vm.handleExceed,
        "on-success": _vm.handleSuccess,
        "before-upload": _vm.handleBeforeUpload,
      },
    },
    [
      _vm._t("default", function () {
        return [
          _c("el-button", { attrs: { size: "small", type: "primary" } }, [
            _vm._v(" 点击上传 "),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }