/**
 * project: user
 * fileName: index
 * Created by 徐俊 on 2020/08/27
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

import Team from "./team";

export const team = new Team();
