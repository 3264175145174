<!--
 * @Author: wuqi
 * @Date: 2020-12-02 11:19:25
 * @LastEditors: wuqi
 * @LastEditTime: 2021-07-15 18:03:06
 * @Description:
-->
<template>
  <div class='new-theme-list'>
    <div class='header-wrap'>
      <en-icon name="icontupian-zuojiantou" class='back-icon' size="small" @click.native="goBack"></en-icon>
      查看消息
    </div>
    <div v-scroll='getCommentList' class='list-wrap'>
      <list
        v-for="item in newsList"
        :key="item.id"
        :listdata="item"
        @delComment="delComment"
        @receiptSuccess='getNewNumber'
      ></list>
    </div>
  </div>
</template>

<script>
import { throttle } from "lodash";
import { mapMutations } from "vuex";
import list from "@/views/companyCircle/list";
import { companyCircleService } from "@/api";

export default {
  name: "NewList",
  data() {
    return {
      newsList: []
    };
  },
  components: {
    list
  },
  directives: {
    scroll: {
      bind(el, binding) {
        let pageNum = 2;
        let hasNext = true;
        el.addEventListener("scroll", throttle(async() => {
          // 滚动条距离顶部高度
          const scrollTop = el.scrollTop;
          // 滚动条高度
          const scrollHeight = el.scrollHeight;
          if (scrollHeight - el.clientHeight - scrollTop < 5 && hasNext) {
          // 判断请求发送标志位，避免重复请求(这个需要自己在data里声明，直接贴代码会报错。默认为false，发送请求前改为true， 请求完成回调函数里改回false)
            hasNext = await binding.value(pageNum);
            hasNext ? pageNum++ : "";
          }
        }, 500));
      },
      // 路由转跳到其他页面时，会调用unbind，解绑滚动加载事件。
      unbind(el, binding, vnode) {
        el.removeEventListener("scroll", vnode.context.scrollLoad);
      }
    }
  },
  computed: {
  },
  created() {
    this.getCommentList(1);
  },
  mounted() {
    this.updateGsqMsgNum(0);
  },
  methods: {
    ...mapMutations("chat", ["updateGsqMsgNum", "updateGsqMsgInfo"]),
    // 返回上一页
    goBack() {
      this.$router.go(-1);
    },
    /**
     * @description: 获取新消息数量和待回执数量
     */
    async getNewNumber() {
      const params = { };
      const rsp = await companyCircleService.readReceipt(params);
      this.updateGsqMsgInfo(rsp);
    },
    /**
     * @description: 获取公司圈消息列表
    */
    async getCommentList(pageNo) {
      const params = {
        pageNo,
        pageSize: 10,
        type: "001",
        ids: this.$route.query.msgIds,
        userId: this.$route.query.userId,
        isNew: "000"
      };
      const rsp = await companyCircleService.themeList(params);
      if (pageNo === 1) {
        this.newsList = rsp.records;
      } else {
        this.newsList = this.newsList.concat(rsp.records);
      }
      return rsp.hasNext;// 滚动加载标志  表示是否还有下一页
    },
    delComment(id) {
      this.$confirm("确认删除此条公司圈?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async() => {
        await companyCircleService.deleteTheme({ themeId: id });
        const index = this.newsList.findIndex((item) => {
          if (item.id === id) {
            return true;
          }
          return false;
        });
        this.newsList.splice(index, 1);
        this.$message({
          type: "success",
          message: "删除成功!"
        });
      });
    }
  }
};
</script>

<style lang='scss' scoped>
.new-theme-list{
  background: #fff;
  .header-wrap{
    height: 50px;
    line-height: 50px;
    padding: 0 15px;
    font-size: 14px;
    border-bottom: 1px solid #dcdfe6;
    .back-icon{
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  }
  .list-wrap{
    height: 100%;
    padding-bottom: 50px;
    overflow: auto;
    padding: 0 15px;
  }
}
</style>
