<!--
 * @Description:公司圈首页 如果路由中有userId 则为他人公司圈，否在就是自己的公司圈
 * @Author: zoujp
 * @Date: 2019-09-26 14:49:25
 * @LastEditTime: 2024-03-04 11:17:00
 -->
<template>
  <div class="home-wrap">
    <div class="content-outer">
      <div class="head-wrap" >
        <div class="head-inner">
          <userLogoInfo :user-id="userInfo.userId" :is-show-circle="true" :user-name='userInfo.name||""' :user-logo='userInfo.userLogo' :logoSize='91'>
          </userLogoInfo>
          <div class="head-tit-wrap">
            <div class="head-tit-inner">
              <div class="head-tit">
                {{userInfo.name}}
              </div>
              <!-- 自己公司圈才显示以下信息 -->
              <div class="head-info" v-if="!$route.query.userId">
                <span v-show="msgCount>0 || count>0">您有</span>
                <span v-show="msgCount>0" ><span class="head-number" @click="toSeeNews"> {{msgCount}} </span> 条新信息</span>
                <span v-show="msgCount>0 && count>0">,</span>
                <span v-show="count>0"><span class="head-number" @click="toSeeNews">  {{count}} </span>  条待回执信息</span>
              </div>
            </div>
            <div class="head-release-wrap" v-if="!$route.query.userId">
              <en-button  icon="iconbianji1" type="text" @click="isShowReleasePanel" > 发布说说 </en-button>
            </div>
          </div>
        </div>
      </div>
      <div  ref="contentWrap" class="content-wrap" :key="$route.query.userId" v-scroll='getCommentList'>
       <transition name="el-fade-in-linear">
          <div v-show="this.isShowRelease" class="content-inner" >
            <div class="form-wrap">
              <div class="form-text">
                <div class="user-wrap" >
                  <template  v-if="showType==1">
                    <span class="user-mod" >#公开</span>
                  </template>
                  <template v-if="showType==2">
                     <span class="user-mod">@本部门</span>
                  </template>
                  <template v-if="noticeList.length&&showType==3">
                     <span class="user-mod" v-for="notice in noticeList" :key="notice.id">@{{notice.name}}</span>
                  </template>
                  <template v-for="receipt in receiptList">
                    <span class="user-mod receipt-mod"  :key="receipt.id">@{{receipt.name}}</span>
                  </template>
                </div>

                <div class="text-wrap">
                  <textarea
                    ref="releaseTextarea"
                    class="r-textarea"
                    maxlength="500"
                    placeholder="说点什么..."
                    rows="3"
                    cols="30"
                    autofocus
                  ></textarea>
                </div>
              </div>
              <div class="form-foot">
                <div class="foot-left">
                  <en-upload class="foot-upload"  :on-change="handleOnChange" limitNum="9" limitType="3" >
                    <en-button icon="iconfujianhetupian-kongjian" type="text" > 图片  </en-button>
                  </en-upload>
                  <el-popover
                    placement="bottom-start"
                    width="400"
                    :offset="20"
                    popper-class="cus-popcss"
                    trigger="click">
                    <en-tree :data="treeList" node-key="id" highlight-current :props="defaultProps" :singleSelect="true" @node-click="changeRelease"  >

                    </en-tree>
                    <div v-show="showType==3" style="overflow:auto">
                      <el-input
                        placeholder="输入关键字进行过滤"
                        v-model="filterText">
                      </el-input>
                      <en-tree ref="tree_dept"
                        :data="treeData" show-checkbox node-key="id"
                        lazy
                        :load="loadNode"
                        highlight-current :props="defaultProps"
                        @check-change="changeMulti"
                        :filter-text="filterText"
                        >
                      </en-tree>
                    </div>

                    <en-button slot="reference" class="foot-left-mod" icon="iconfabu-gongsiquan"  type="text" >
                      发布范围
                    </en-button>
                  </el-popover>
                  <el-popover
                    ref="pophz"
                    placement="bottom-start"
                    width="400"
                    :offset="20"
                    popper-class="cus-popcss"
                    trigger="click">
                    <el-input
                        placeholder="输入关键字进行过滤"
                        v-model="filterText2">
                      </el-input>
                    <en-tree
                      ref="replyrange"
                      :data="treeData"
                      check-mode='parent-children'
                      show-checkbox
                      node-key="id"
                      lazy
                      :load="loadNode"
                      highlight-current
                      :props="defaultProps"
                      @check-change="replyRangechange"
                      :filter-text="filterText2"
                    >
                    </en-tree>
                    <en-button
                      slot="reference"
                      class="foot-left-mod"
                      icon="iconhuizhi-gongsiquan"
                      type="text"
                    >
                      回执范围
                    </en-button>
                  </el-popover>
                </div>
                <div class="foot-right">
                  <en-button class="btn-width" @click="handleSendTheme">
                    发布
                  </en-button>
                </div>
              </div>
              <div class="form-att atta-file-wrap" v-if="imgList.length" >
                 <atta-img v-for="(item,_ind) in imgList" :key="item.code"  @click.native="viewImage(_ind)" :imgdata="item"   @delImg="()=>{deleteRow(_ind,imgList)}"></atta-img>
              </div>
              <!-- <div class="form-att atta-file-wrap" v-if="fileList.length">
                <atta-file v-for="fileItem in fileList" :filedata='fileItem' :key="fileItem.code"></atta-file>
              </div> -->
            </div>
        </div>
      </transition>

        <div class="list-wrap" >
          <list
            v-for="item in newsList"
            :key="item.id"
            :listdata="item"
            @delComment="delComment"
            @receiptSuccess='getNewNumber'
          ></list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

// import attachment from "@/views/companyCircle/attachment";
import { mapGetters, mapState, mapMutations } from "vuex";
import { throttle } from "lodash";
import attaImg from "@/views/companyCircle/atta-img";
import list from "@/views/companyCircle/list";
import userLogoInfo from "@/components/user-logo-info";
// import enUpload from "@/views/companyCircle/upload";
import { companyCircleService, settingService } from "@/api";
import { team } from "@/api/chat";

export default {
  name: "Home",
  components: {
    list, userLogoInfo, attaImg
  },
  computed: {
    userInfo() {
      return !this.$route.query.userId ? this.$store.state.userInfo : this.otherUserInfo;
    },
    ...mapState("chat", ["gsqMsgNum"]),
    ...mapGetters(["urlParam"]),
    msgCount() {
      return this.gsqMsgNum.msgCount;
    },
    count() {
      return this.gsqMsgNum.count;
    }
  },
  data() {
    return {
      otherUserInfo: { userId: "", name: "" }, // 他人公司圈  用户信息
      pageLoading: false, // v-if 重新渲染说说列表
      prefileList: [],
      noticeList: [], // 发布范围
      receiptList: [], // 回执范围
      filterText: "", // 发布范围搜索过滤
      filterText2: "", // 回执范围搜索
      showType: 1, // 发布范围  1 ：公开{"userType":"003","type":"002"}， 2 ：部门{"objectId":"","type":"002","userType":"004"}， 3 ：@ {"objectId":"318107823933296640","type":"002"}
      showListTree: false,
      show2Tree: false,
      themeInfo: {
        files: "",
        content: "",
        toNotices: "",
        isReceipt: "",
        toReceipt: ""
      }, // 发布说说的信息

      defaultProps: {
        value: "id", label: "name", children: "nodes", isLeaf: this.isLeaf
      },
      treeData: [],
      treeList: [
        {
          id: 1,
          name: "公开"
        },
        {
          id: 2,
          name: "本部门"
        },
        {
          id: 3,
          name: "@"
        }
      ],
      isShowRelease: false, // 是否显示发布公司圈面板
      uploadParams: { }, // 上传组件参数
      // 公司圈信息列表数据
      newsList: [],

      fileList: [],
      imgList: []
    };
  },
  directives: {
    scroll: {
      bind(el, binding) {
        let pageNum = 2;
        let hasNext = true;
        el.addEventListener("scroll", throttle(async() => {
          // 滚动条距离顶部高度
          const scrollTop = el.scrollTop;
          // 滚动条高度
          const scrollHeight = el.scrollHeight;
          if (scrollHeight - el.clientHeight - scrollTop < 5 && hasNext) {
          // 判断请求发送标志位，避免重复请求(这个需要自己在data里声明，直接贴代码会报错。默认为false，发送请求前改为true， 请求完成回调函数里改回false)
            hasNext = await binding.value(pageNum);
            hasNext ? pageNum++ : "";
          }
        }, 500));
      },
      // 路由转跳到其他页面时，会调用unbind，解绑滚动加载事件。
      unbind(el, binding, vnode) {
        el.removeEventListener("scroll", vnode.context.scrollLoad);
      }
    }
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: {
      handler(to, form) {
        console.log(to, form);
        this.getCommentList(1);
        this.getNewNumber();
        this.getOtherUserInfo();
      },
      immediate: true,
      deep: true
    },
    msgCount(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getCommentList(1);
      }
    },
    count(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getCommentList(1);
      }
    }

  },

  mounted() {
    this.uploadParams = {
      appKey: this.urlParam.appKey,
      token: this.urlParam.token,
      ts: this.urlParam.ts
    };
  },
  methods: {
    ...mapMutations("chat", ["updateGsqMsgInfo"]),
     /**
     * @description: 查看图片
     * @param index
     */
    viewImage(index) {
      this.$viewImage({
        datas: this.imgList,
        url: "url",
        thumUrl: "url",
        initialViewIndex: index
      });
    },
    // 获取用户信息
    async  getOtherUserInfo() {
      if (!this.$route.query.userId) return;
      this.otherUserInfo = await team.queryUserInfo({ id: this.$route.query.userId });
      this.otherUserInfo.userId = this.otherUserInfo.id;
    },
    // 发布说说
    async handleSendTheme() {
      const themeInfo = {};
      themeInfo.content = this.$refs.releaseTextarea.value;
      if (!themeInfo.content) {
        this.$message.error("请先说点说什么吧！");
        return;
      }
      themeInfo.files = this.imgList.map((item) => ({
        fileName: item.name, filePath: item.code, fileSize: `${item.size}`, type: item.dataType
      }));
      themeInfo.isReceipt = this.receiptList.length ? "000" : "001";// 需要回执则为000
      themeInfo.toReceipt = this.receiptList.map((item) => ({ type: "001", objectId: item.id }));
      if (this.showType === 3) { // 发布范围为指定@谁
        themeInfo.toNotices = this.noticeList.length ? this.noticeList.map((item) => ({ objectId: item.id, type: "002" })) : this.$message.error("请选择要@的对象");
      } else if (this.showType === 2) {
        themeInfo.toNotices = [{ objectId: "", type: "002", userType: "004" }];
      } else {
        themeInfo.toNotices = [{ userType: "003", type: "002" }];
      }

      themeInfo.files = JSON.stringify(themeInfo.files);
      themeInfo.toReceipt = JSON.stringify(themeInfo.toReceipt);
      themeInfo.toNotices = JSON.stringify(themeInfo.toNotices);

      await companyCircleService.sendTheme(themeInfo);
      this.$message.success("发布成功");
      this.resetForm();
      this.getCommentList(1);
    },
    // 清空发布说说表单
    resetForm() {
      this.$nextTick(() => {
        this.$set(this.$refs.releaseTextarea, "value", "");
      });
      this.imgList = [];
      this.$refs.tree_dept.setCheckedKeys([]);
      this.$refs.replyrange.setCheckedKeys([]);
    },
    // 删除元素
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    // 获取机构成员树
    async queryDeptUserTree(id) {
      const resp = await settingService.queryDeptUserTree({ id });
      this.treeData = resp;
    },

    /**
     * @description: 指定节点是否为叶子节点
     * @param data 节点数据
     * @return:
     */
    isLeaf(data) {
      return data.hasChildren === "001";
    },
    /**
     * @description: 树 异步加载子节点
     * @param node
     * @param resolve
     */
    async loadNode(node, resolve) {
      const { data } = node;
      const { hasChildren, id } = data;
      if (hasChildren === "000") {
        // 有子项
        const rsp = await settingService.queryDeptUserTree({ id });
        this.$nextTick(() => {
          this.replyRangechange();
        });
        resolve(rsp);
      } else {
        resolve([]);
      }
    },

    replyRangechange() {
      const ns = this.$refs.replyrange.getCheckedNodes(false, true);
      this.receiptList = ns.filter((i) => i.isParent === "001");
    },
    /*
      选择发布弹窗
      @data:当前选中节点数据
    */
    changeRelease(data) {
      const { id } = data;
      this.showType = id;
    },
    /*
      选择发布@谁
    */
    changeMulti() {
      const ns = this.$refs.tree_dept.getCheckedNodes(false, true);
      this.noticeList = ns;
    },
    /*
      隐藏@谁弹窗
    */
    hideTree() {
      // this.showType = 1;
      // this.showListTree = false;
      // this.show2Tree = false;
    },
    /**
     * @description: 跳转查看新消息
    */
    toSeeNews() {
      // console.log("");
      this.$router.push({ path: "newthemelist", query: { msgIds: this.gsqMsgNum.themeIds } });
    },
    /**
     * @description: 跳转查看待回执
    */
    toSeeReceipt() {
      console.log("");
    },
    /**
     * @description: 获取公司圈消息列表
    */
    async getCommentList(pageNo) {
      const params = {
        pageNo,
        pageSize: 10,
        type: this.$route.query.userId ? "001" : "000",
        userId: this.$route.query.userId
      };
      const rsp = await companyCircleService.themeList(params);
      if (pageNo === 1) {
        this.newsList = rsp.records;
      } else {
        this.newsList = this.newsList.concat(rsp.records);
      }
      return rsp.hasNext;// 滚动加载标志  表示是否还有下一页
    },
    /**
     * @description: 获取新消息数量和待回执数量
     */
    async getNewNumber() {
      if (this.$route.query.userId) return;// 如果是他人公司圈则直接return
      const params = { };
      const rsp = await companyCircleService.readReceipt(params);
      this.updateGsqMsgInfo(rsp);
    },
    /**
       @description: 触发显示隐藏发布公司面板
    */
    isShowReleasePanel() {
      this.isShowRelease = !this.isShowRelease;
      this.$refs.contentWrap.scrollTop = 0;
      const This = this;
      this.queryDeptUserTree(0);
      setTimeout(() => {
        This.$refs.releaseTextarea.focus();
      }, 1000);
    },
    /*
      添加人（未完成，需要先优化下拉树组件）
    */
    addUser() {
      console.log(11);
    },
    /*
      添加回执人（未完成，需要先优化下拉树组件）
    */
    addReceipt() {
      console.log(111);
    },
    /*
      删除评论
      @id 评论id
    */
    delComment(id) {
      this.$confirm("确认删除此条公司圈?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async() => {
        await companyCircleService.deleteTheme({ themeId: id });
        const index = this.newsList.findIndex((item) => {
          if (item.id === id) {
            return true;
          }
          return false;
        });
        this.newsList.splice(index, 1);
        this.$message({
          type: "success",
          message: "删除成功!"
        });
      });
    },
    /**
     * @description: 发布说说
     * @param {*}
     * @return {*}
     */
    pushShare() {

    },
    /**
     * @description: 上传附件
     * @param {*} file 当前文件
     * @param {*} progress 进度
     * @return {*}
     */
    handleOnChange({ file }) {
      if (file.progress === 100) {
        if (file.fileData.type.indexOf("image/") > -1) {
          this.imgList.length < 9 ? this.imgList.push(file) : this.$message.error("最多只能上传9张图片");
        } else {
          this.$message.error("附件只支持上传图片");
        }
      }
    }
  }
};
</script>
<style lang='scss'>
  .cus-popcss{
    .el-tree{
      max-height: 300px;
      overflow: auto;
    }
  }
</style>
<style lang='scss' scoped>
.home-wrap {
  width: 100%;
  height: 100%;
  // background-color: #1d2532;
  .content-outer {
    height: 100%;
    padding: 0;
    overflow-y: auto;
    .head-wrap {
      width: 100%;
      height: 123px;
      border-radius: 4px;
      background: url("../../assets/images/banner.jpg") no-repeat right center;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 83px;
        background-color: rgba($color: #fff, $alpha: 0.9);
        border-radius: 0 0 4px 4px;
      }
      .head-inner {
        padding-top: 17px;
        padding-bottom: 17px;
        padding-left: 40px;
        display: flex;
        .pop-cls {
          z-index: 1;
          cursor: pointer;
        }
        .head-circle {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          cursor: pointer;
        }
        .head-tit-wrap {
          width: 100%;
          margin-left: 18px;
          padding-top: 15px;
          z-index: 1;
          position: relative;
          .head-tit-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 80%;
            height: 100%;
            text-align: left;
            .head-tit {
              font-size: 16px;
              color: #333333;
              font-weight: bold;
            }
            .head-info {
              color: #636c78;
              margin-top: 5px;
              font-size: 12px;
              .head-number {
                color: #f99798;
                font-weight: bold;
                cursor: pointer;
              }
            }
          }
          .head-release-wrap {
            position: absolute;
            top: 50px;
            right: 20px;
          }
        }
      }
    }
    .head-wrap-other{

    }
    .content-wrap {
      width: 100%;
      height: calc(100% - 129px);
      margin-top: 6px;
      border-radius: 4px;
      overflow-y: auto;
      background-color: #ffffff;
      .list-wrap{
        padding: 0 20px;
      }
      .content-inner {
        padding: 10px 20px 0 20px;
        .form-wrap {
          border-radius: 3px;
          border: 1px solid #ecf1f7;
          .form-text {
            min-height: 100px;
            .text-wrap {
              .r-textarea {
                border: 0;
                width: 100%;
                padding: 0 20px;
                resize: none;
                font-size: 12px;
                background-color: #fff;
                background-image: none;
                color: #777e89;
                outline: none;
              }
            }
          }
          .user-wrap {
              overflow: hidden;
              text-align: left;
              padding: 10px;
              .user-mod {
                background: #ecf4fc;
                font-size: 13px;
                color: #67a7e5;
                border-radius: 10px;
                padding: 0 9px;
                margin: 0 0 5px 5px;
                cursor: pointer;
                display: inline-block;
                height: 22px;
                line-height: 22px;
              }
              .receipt-mod{
                background: #4ed3c3;
                color:#fff
              }
            }
          .form-att {
            padding: 10px 0 5px 20px;
            background-color: #f5f7fa;
            display: flex;
            flex-direction: column;
          }
          .atta-file-wrap{
            flex-direction: row;
            flex-wrap: wrap;
          }
          .form-foot {
            height: 50px;
            background-color: #f5f7fa;
            border-top: 1px solid #ecf1f7;
            box-sizing: border-box;
            .foot-left {
              float: left;
              padding-top: 8px;
              padding-left: 20px;
            }
            .foot-left-mod {
              padding-left: 20px;
            }
            .foot-upload {
              display: inline-block;
            }
            .foot-right {
              float: right;
              padding-right: 20px;
              padding-top: 10px;
            }
          }
        }
      }
    }
  }
  .bg-gray {
    background-color: #a9b5c6;
    border: 1px solid #a9b5c6;
  }
  .btn-width {
    width: 60px;
  }
  .btn-height {
    height: 60px;
  }
}
</style>;
