var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "span",
      { staticClass: "img-wrap" },
      [
        _c("el-image", {
          staticClass: "img wh",
          attrs: {
            src: _vm.cleImg.url || _vm.cleImg.code || _vm.cleImg.filePath,
            fit: "cover",
            title: _vm.cleImg.name,
            lazy: "",
          },
        }),
        _vm.deleteabled
          ? _c(
              "span",
              { staticClass: "del", on: { click: _vm.delFile } },
              [
                _c("en-icon", {
                  attrs: { name: "shanchu-liebiao", size: "small" },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }