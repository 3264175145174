/*
 * @Author: zoujp
 * @Date: 2020-06-19 17:17:13
 * @LastEditTime: 2020-06-19 17:29:29
 * @LastEditors: Please set LastEditors
 * @Description: 公司圈公共方法
 * @FilePath: \user\src\mixins\companyCircle\companyComm.js
 */

export default {
  methods: {
    /**
       * @description: 格式化时间
       * @param {timeStamp} 时间戳
       * @return:返回格式化之后的时间
       */
    getToday(dd) {
      const d = dd || new Date();
      const m = (`00${d.getMonth() + 1}`);
      const r = (`00${d.getDate()}`);
      return `${d.getFullYear()}-${m.substr(m.length - 2)}-${r.substr(r.length - 2)}`;
    },
    getMD(dd) {
      const d = dd || new Date();
      const m = (`00${d.getMonth() + 1}`);
      const r = (`00${d.getDate()}`);
      return `${m.substr(m.length - 2)}-${r.substr(r.length - 2)}`;
    },
    getFormatTime(timeStamp) {
      const dd = new Date(timeStamp);
      let ad = this.getToday(dd);
      let bd = this.getToday();
      let reDate;
      const theYear = dd.getFullYear();
      const curYear = new Date(bd).getFullYear();
      if (theYear === curYear) { // 当年
        ad = dd.getTime();
        bd = new Date().getTime();
        const diffHour = (bd - ad) / (3600 * 1000);
        if (diffHour < 1) { // 60分钟内
          reDate = `${Math.ceil(diffHour * 60)}分钟前`;
        } else if (diffHour >= 1 && diffHour < 24) {
          reDate = `${Math.ceil(diffHour)}小时前`;
        } else {
          const days = Math.ceil((bd - ad) / (24 * 3600 * 1000));
          if (days >= 1 && days < 7) {
            reDate = `${days}天前`;
          } else {
            reDate = this.getMD(new Date(dd));
          }
        }
      } else { // 不是今年
        reDate = ad;
      }
      return reDate;
    }
  }
};
