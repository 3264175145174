var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "att-img" }, [
    _c(
      "div",
      { staticClass: "att-img-inner" },
      _vm._l(_vm.imgList, function (item, ind) {
        return _c("attaImg", {
          key: item.id,
          attrs: { deleteabled: false, imgdata: item },
          on: { delImg: _vm.delImg },
          nativeOn: {
            click: function ($event) {
              return _vm.viewImage(ind)
            },
          },
        })
      }),
      1
    ),
    _c(
      "div",
      [
        _c("en-button", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.imgList.length > 9,
              expression: "imgList.length>9",
            },
          ],
          staticClass: "btn-width btn-height bg-gray",
          attrs: { icon: "icongengduotupian-gongsiquan", "icon-color": "#fff" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }