<!--
 * @Author: your name
 * @Date: 2020-06-10 10:49:24
 * @LastEditTime: 2020-12-01 14:29:25
 * @LastEditors: wuqi
 * @Description: In User Settings Edit
 * @FilePath: \user\src\views\companyCircle\attachment.vue
-->
<template>
    <div class="att-img">
      <div class="att-img-inner" >
        <attaImg
          v-for="(item,ind) in imgList"
          @click.native="viewImage(ind)"
          :key="item.id"
          :deleteabled='false'
          :imgdata="item"
          @delImg="delImg"
        >
        </attaImg>
      </div>
      <div>
        <en-button
          v-show="imgList.length>9"
          class="btn-width btn-height bg-gray"
          icon="icongengduotupian-gongsiquan"
          icon-color="#fff"
        ></en-button>
      </div>
    </div>
</template>
<script>
import attaImg from "@/views/companyCircle/atta-img"; // 引入图片组件

export default {
  name: "Attachment",
  components: { attaImg },
  props: {
    // 所有图片数据
    imgList: {
      type: Array,
      required: true
    }
  },
  data() {
    // 是否显示附件
    return {
      isShow: false

    };
  },
  methods: {
    /**
     * @description: 查看图片
     * @param index
     */
    viewImage(index) {
      this.$viewImage({
        datas: this.imgList,
        url: "filePath",
        thumUrl: "filePath",
        initialViewIndex: index
      });
    },
    // 删除一个图片方法
    delImg(id) {
      // if (this.flag === 1) {
      //   this.imgList.splice(this.imgList.findIndex((item) => item.id === id), 1);
      // } else {
      //   // 请求后台
      console.log(id);
      // }
    }
  }
};
</script>
<style lang='scss' scoped>

.att-img {
    display: flex;
    .att-img-inner {
      display: flex;
      cursor: pointer;
    }
  }
.att-file {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
</style>;
